<template>
  <div class="root text-center">
    <!-- <img
      class="mainTitle"
      src="@/assets/aiShelter/textLogo.png"
      alt="แชะ&โชว์ logo"
    /> -->
    <h3 class="mainTitle">แชะ&โชว์ รับพ้อยสุดฟิน</h3>
    <div class="p-3">
      <div class="pointBox">
        <div>{{ isNumber(point) ? point.toLocaleString() : "" }}</div>
        <div>คะแนน</div>
      </div>
    </div>
    <div class="text-left pl-3 mt-2 font-weight-bold">ประวัติคะแนน</div>
    <div
      style="
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 10px;
        padding-bottom: 80px;
      "
    >
      <div
        v-for="(item, index) in items"
        :key="index"
        style="
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 1em;
          width: 100%;
          color: black;
          background-color: white;
          border-radius: 1em;
        "
      >
        <div style="display: flex; align-items: center; width: 100%; gap: 10px">
          <div
            @click="() => item.type != 'extra' && showImage(item.image)"
            style="max-width: 20%; aspect-ratio: 1/1; border-radius: 12px"
          >
            <img
              width="100%"
              height="auto"
              :src="item.image || require('@/assets/logo_2022.png')"
              alt=""
            />
          </div>
          <div style="width: 100%; text-align: left; font-size: 0.8em">
            <div>
              วันที่ส่งรูป:
              {{
                Date.parse(item.createdAt)
                  ? new Date(item.createdAt).toLocaleDateString("th-TH", {
                      hour12: false,
                    })
                  : item.createdAt
              }}
            </div>
            <div>เลขที่: {{ item.id }}</div>
            <div>
              <span>สถานะ: </span>
              <span v-if="item.status == 'success' && item.type == 'image'"
                >ได้รับคะแนน</span
              >
              <span v-if="item.status == 'success' && item.type == 'extra'"
                >ได้รับคะแนนพิเศษ</span
              >
              <span v-if="item.status == 'pending'">รอการตรวจสอบ</span>
              <span v-if="item.status == 'incorrect'">ไม่ได้รับคะแนน</span>
            </div>
            <div>
              วันที่ได้รับคะแนน:
              {{
                Date.parse(item.updatedAt)
                  ? new Date(item.updatedAt).toLocaleDateString("th-TH", {
                      hour12: false,
                    })
                  : item.updatedAt
              }}
            </div>
          </div>
        </div>
        <div style="width: 38%">
          <div v-if="item.type == 'extra'" style="color: green">
            + {{ item.point }}
          </div>
          <div v-if="item.status == 'pending'" style="font-size: 0.8em">
            กำลังตรวจสอบ
          </div>
          <span
            v-if="item.status == 'success' && item.type == 'image'"
            style="color: green"
            >+
            {{ isNumber(item.point) ? item.point.toLocaleString() : "-" }}</span
          >
          <span
            v-if="item.status == 'incorrect'"
            style="color: red; font-size: 0.8em"
            >ไม่ได้รับคะแนน</span
          >
        </div>
      </div>
      <infinite-loading
        ref="infiniteLoading"
        @infinite="getHistory"
        spinner="waveDots"
      >
        >
        <div slot="no-more"></div>
        <div slot="no-results">ไม่พบข้อมูล</div>
      </infinite-loading>
    </div>

    <b-modal id="modal" hide-footer hide-header-close centered>
      <div class="d-block text-center">
        <img width="100%" style="min-height: 300px" :src="imageModal" alt="" />
      </div>
      <div class="d-block text-center mt-3">
        <button
          id="close-event"
          class="btn btn-primary"
          @click="closeShowImage"
        >
          ปิด
        </button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { isNumber } from "lodash";
import InfiniteLoading from "vue-infinite-loading";

export default {
  name: "ai-shelf-detection-history",
  components: {
    InfiniteLoading,
  },
  data() {
    return {
      imageModal: null,
      point: 0,
      items: [],
      lastId: 0,
      perPage: 10,
      page: 1,
    };
  },
  methods: {
    isNumber: isNumber,
    showImage(image) {
      this.imageModal = image;
      this.$bvModal.show("modal");
    },
    closeShowImage() {
      this.$bvModal.hide("modal");
      this.imageModal = null;
    },
    async getHistory($state) {
      await fetch(
        `${process.env.VUE_APP_API_URL}/ai-shelfs/transactions?lastId=${this.lastId}&perPage=${this.perPage}&page=${this.page}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${this.$JWT}`,
          },
        }
      )
        .then(async (res) => {
          let body = null;
          switch (res.status) {
            case 200:
              body = (await res.json()) || null;

              if (
                body &&
                body.transactionRes &&
                body.transactionRes.length > 0
              ) {
                this.lastId = body.lastId;
                this.items = [...this.items, ...body.transactionRes];
                this.point = body.totalPoint;
                this.page++;

                setTimeout(() => {
                  $state.loaded();
                }, 200);
              } else {
                $state.complete();
              }

              break;
            default: {
              console.log("Get history OC");
              $state.complete();
              this.$Error();
            }
          }
        })
        .catch(() => {
          console.log("Get history Err");
          this.$refs.infiniteLoading.stateChanger.complete();
          this.$Error();
        });
    },
  },
  mounted() {
    window.loading(false);
  },
};
</script>

<style lang="scss">
$button-blue: #0048c0bf;
$border-blue: #03ffff;

#close-event {
  border-color: $border-blue;
  border-radius: 25px;
  background: $button-blue;
  width: 250px;
  height: 50px;
}
</style>

<style lang="scss" scoped>
$button-blue: #0048c0bf;
$border-blue: #03ffff;

$button-white: #e0e0e0;
$border-gray: #979797;

.root {
  background: url("~@/assets/bg_ai.jpg");
  //   background-attachment: scroll;
  background-repeat: no-repeat;
  background-position: top;
  background-attachment: fixed;
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
  overflow: scroll;
  color: white;
  font-size: 16px;
  padding-top: 15px;
  line-height: 1.3em;

  // .mainTitle {
  //   margin-top: 3rem;
  //   max-width: 360px;
  // }

  .mainTitle {
    margin-top: 2rem;
    text-shadow: 0 0 4px #3066ed, 0 0 4px #3066ed;
  }

  .pointBox {
    color: #000000;
    background: conic-gradient(
      from 180deg at 50% 50%,
      #00b0fb 0deg,
      #a7e4ff 43.2deg,
      #00b0fb 360deg
    );
    border-radius: 15px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 12px;
    min-width: 250px;
    max-width: 100%;
    width: max-content;
    padding: 25px 15px;
    margin: auto;

    :nth-child(1) {
      font-size: 1.8em;
    }

    :nth-child(2) {
      font-size: 0.8em;
    }
  }
}
</style>
